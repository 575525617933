import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { RBACWrapper } from "react-simple-rbac";
import './CommunicationHistory.scss';
import SearchAndFilter from '../../../components/CommunicationHistory/SearchAndFilter';
import { Icons, Spinner } from '@cd/sdds-common-components-react';
import { clearCommunicationData, loadCommunicationData, sortCommunicationMessageDataList, filterCommunicationMessageDataList, startActionLoader, completeActionLoader, setSearchInputsIntoStore, setSearchCheckboxInputsIntoStore, combineLoadedData, clearCombinedResults } from '../../../redux/store/communicationHistory';
import { isoFormatFromDate } from "../../../helpers/timeFormatter";
import CommunicationHistoryDataTable from '../../../components/CommunicationHistory/CommunicationHistoryDataTable';
import { useAppSelector } from '../../../redux/store';
import CommHistoryExcelExportButton from "../../../components/CommunicationHistory/ExcelExportButton";
import CommunicationHistoryMap from "../../../components/CommunicationHistoryMap";
import { parseDataForExcelExport } from "../../../helpers/commHistoryExportService";
import ExportIcon from "../../../icons/ExportIcon";
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../redux/store';
import { clearHistoricalDataVariables, loadhistoricalDataVariables } from '../../../redux/store/historicalDataVariables';
import _ from "lodash"
import { TimeLineChart, PieChart } from '../../../components/AmCharts';
import InfoIconWithLabel from '../../../components/InfoIconWithLabel';
import { UserContext } from '../../../appContext';
import { clone } from "lodash";
import LegendIndicator from '../../../components/CommunicationHistory/LagendIndicators';
import MessageFiltersAndFunctions from '../../../components/CommunicationHistory/MessageFiltersAndFunctions';
import { useToast, Toast } from '@cd/sdds-common-components-react';
import NoDataFoundMessage from './noDataFoundMessage';
import { InfoIconWithTooltip } from '@cd/sdds-common-components-react/lib/esm/components/assets';


const CommunicationHistory = () => {
  const tabsRef = React.createRef<HTMLTdsFolderTabsElement>();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { selectedVehicle, selectedCustomer } = useAppSelector(state => state.universalSearch);
  const { isLoading, combinnedCommunicationData, currentStatusList, positionList, functionIndicationList, searchInput, searchEquipmentReference, hasError, errorMessage, statusCode } = useAppSelector<CommunicationStateType>(state => state.communicationHistoryStore);
  const { historicalDataVariables } = useAppSelector<HistoricalDataStateType>(state => state.historicalDataStore);
  const [paginationReset, setPaginationReset] = useState(false);
  const [exportData, setExportData] = useState<Array<any>>([]);
  const { settings } = useContext(UserContext);
  const [unitType, setUnitType] = useState(settings?.unit || 'Metric');
  const [messageFilterTypes, setMessageFilterTypes] = useState<string[]>(['CurrentStatus', 'Position', 'FunctionIndication']);
  const [showMinifiedSearchFilter, setShowMinifiedSearchFilter] = useState(false);
  const [messageFilterChecks, setMessageFilterChecks] = useState(new Map([["CurrentStatus", true], ["Position", true], ["FunctionIndication", true]]));
  const { toastQueue, addToast } = useToast();
  const loggedInProfileInfo = useContext<ProfileInformationType>(UserContext);

  useEffect(() => {
    if (tabsRef.current && combinnedCommunicationData.length > 0) {
      tabsRef.current.addEventListener("tdsChange", (event: any) => {
        if (event && event.detail) {
          setSelectedTabIndex(event.detail.selectedTabIndex)
        }
      });
    }
  }, [combinnedCommunicationData, tabsRef.current])
  useEffect(() => {
    setUnitType(settings?.unit || "Metric")
  }, [settings]);

  useEffect(() => {
    if (searchEquipmentReference !== selectedVehicle.externalEquipmentReference) {
      setExportData([])
      dispatch(clearCommunicationData())
      dispatch(clearHistoricalDataVariables())
      dispatch(setSearchInputsIntoStore('', '', [], ''))
      dispatch(setSearchCheckboxInputsIntoStore({ "CurrentStatus": true, "Position": true, "FunctionIndication": true }));
    }
  }, [selectedVehicle.externalEquipmentReference])


  useEffect(() => {
    dispatch(filterCommunicationMessageDataList(messageFilterTypes));
  }, [messageFilterChecks]);

  useEffect(() => {
    if (hasError && errorMessage !== '') {
      if (statusCode === 408) {
        addToast({
          title: "Request timeout", text: "A request timeout has occured, Please try again later",
          type: "error"
        })
      }
      else {
        addToast({
          title: t("TS_core:UnExpectedErrorTitle"), text: errorMessage,
          type: "error"
        })
      }
    }
  }, [hasError, errorMessage]);

  const onMessageFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let mTypes: string[] = clone(messageFilterTypes)
    let messageChecks: Map<string, boolean> = clone(messageFilterChecks)

    const checkBoxValue = e.target.value;

    if (e.target.checked) {
      if (!mTypes.includes(checkBoxValue)) {
        mTypes = mTypes.concat([checkBoxValue]);
        messageChecks.set(checkBoxValue, true);
      }
    } else {
      if (mTypes.includes(checkBoxValue)) {
        mTypes.splice(mTypes.indexOf(checkBoxValue), 1);
        messageChecks.set(checkBoxValue, false);
      }
    }
    setMessageFilterChecks(messageChecks);
    setMessageFilterTypes(mTypes);
    dispatch(filterCommunicationMessageDataList(mTypes));
  }

  useEffect(() => {
    let exportDataList: CommHistoryExcelExportDataType = { currentstatus: currentStatusList.data, functionIndication: functionIndicationList.data, position: positionList.data }
    let messageType = searchInput?.messageTypes ? searchInput?.messageTypes : [];
    setExportData(parseDataForExcelExport(exportDataList, messageType, unitType));
  }, [currentStatusList, functionIndicationList, positionList]);

  const handleOnSearch = async (fromDate: string, toDate: string, messageTypes: string[]) => {
    setPaginationReset(!paginationReset)
    dispatch(setSearchInputsIntoStore(fromDate, toDate, messageTypes, selectedVehicle.externalEquipmentReference))
    setExportData([])
    dispatch(clearCommunicationData())
    dispatch(clearHistoricalDataVariables())
    dispatch(startActionLoader());
    const actions: any = [];
    if (messageTypes.length > 0) {
      messageTypes.forEach(messageType => {
        actions.push(dispatch(loadCommunicationData(selectedVehicle.externalEquipmentReference, isoFormatFromDate(fromDate), isoFormatFromDate(toDate), messageType.toLocaleLowerCase(), "", unitType)));
        dispatch(loadhistoricalDataVariables(messageType));
      });
      await Promise.all(actions);
      dispatch(combineLoadedData(messageFilterChecks));
    }
    dispatch(completeActionLoader());
  }

  const onSortData = (columnId: string, sortDirection: string) => {
    if (combinnedCommunicationData && combinnedCommunicationData.length > 0) {
      let sortedResults = _.sortBy(combinnedCommunicationData, columnId);
      if (sortDirection === 'desc') {
        sortedResults = _.reverse(sortedResults);
      }
      dispatch(sortCommunicationMessageDataList(sortedResults as Array<any>));
    }
  }

  const handleLoadMoreMessages = async () => {
    dispatch(startActionLoader());
    dispatch(clearCombinedResults());
    const actions: any = [];
    if (searchInput && searchInput.messageTypes.length > 0) {
      searchInput.messageTypes.forEach((messageType) => {
        if (messageType.toLocaleLowerCase() === 'position' && (positionList?.data?.length > 0) && (positionList?.paginationtoken && positionList?.paginationtoken !== "")) {
          actions.push(dispatch(loadCommunicationData(selectedVehicle.externalEquipmentReference, searchInput.fromDate, searchInput.toDate, messageType.toLocaleLowerCase(), positionList.paginationtoken, unitType)));
        }
        else if (messageType.toLocaleLowerCase() === 'functionindication' && (functionIndicationList?.data?.length > 0) && (functionIndicationList?.paginationtoken && functionIndicationList?.paginationtoken !== "")) {
          actions.push(dispatch(loadCommunicationData(selectedVehicle.externalEquipmentReference, searchInput.fromDate, searchInput.toDate, messageType.toLocaleLowerCase(), functionIndicationList.paginationtoken, unitType)));
        }
        else if (messageType.toLocaleLowerCase() === 'currentstatus' && (currentStatusList?.data?.length > 0) && (currentStatusList?.paginationtoken && currentStatusList?.paginationtoken !== "")) {
          actions.push(dispatch(loadCommunicationData(selectedVehicle.externalEquipmentReference, searchInput.fromDate, searchInput.toDate, messageType.toLocaleLowerCase(), currentStatusList.paginationtoken, unitType)));
        }
      });

      await Promise.all(actions);
      dispatch(combineLoadedData(messageFilterChecks));
      dispatch(completeActionLoader());
    }
  }

  const handleScroll = (event: SyntheticEvent) => {
    let scrollPosition = event.currentTarget.scrollTop;
    if (scrollPosition > 150) {
      setShowMinifiedSearchFilter(true)
    }
    else if (scrollPosition < 1) {
      setShowMinifiedSearchFilter(false)
      event.currentTarget.scrollTo({ top: 0 })
    }
  }

  return <>
    {((selectedCustomer && selectedCustomer?.scaniaLegalDocumentationSigned && selectedCustomer?.scaniaRead) || ((selectedCustomer === undefined || selectedCustomer === null) && (loggedInProfileInfo?.role === 'Admin' || loggedInProfileInfo?.role === 'GTS'))) &&
      <div className="panel active" onScroll={handleScroll} >
        <SearchAndFilter searchInputPrnt={searchInput} onSearch={handleOnSearch} />
        <div className={`${showMinifiedSearchFilter ? 'sticky' : ''}`}>
          <MessageFiltersAndFunctions
            combinnedCommunicationData={combinnedCommunicationData}
            currentStatusList={currentStatusList}
            functionIndicationList={functionIndicationList}
            handleLoadMoreMessages={handleLoadMoreMessages}
            onMessageFilterChangeInChild={onMessageFilterChange}
            positionList={positionList}
            messageFilterChecks={messageFilterChecks}
            isLoading={isLoading}

          ><CommHistoryExcelExportButton
            data={exportData}
            selectedMessageTypes={searchInput?.messageTypes ? searchInput?.messageTypes : []}
            disabled={combinnedCommunicationData.length === 0}
            fromDate={searchInput?.fromDate}
            toDate={searchInput?.toDate}
            vin={selectedVehicle?.identification}
          >
              <span>{t("TS_core:ExportToExcel")}</span><span className='sdds-btn-icon'><ExportIcon /></span>
            </CommHistoryExcelExportButton></MessageFiltersAndFunctions>
        </div>

        <div className='comm-history-page sdds-container-fluid'>
          {(combinnedCommunicationData.length > 0) ? <><LegendIndicator />
            <tds-folder-tabs ref={tabsRef}>
              <tds-folder-tab>
                <button>{t("TS_core:Table")}</button>
              </tds-folder-tab>
              <tds-folder-tab>
                <button>{t("TS_core:Charts")}</button>
              </tds-folder-tab>
              <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Assistance"]} >
                <tds-folder-tab>
                  <button>{t("TS_core:Map")}</button>
                </tds-folder-tab>
              </RBACWrapper>
            </tds-folder-tabs>

            <>
              {selectedTabIndex === 0 && <div className='tab-container' >
                <div className="sdds-row sdds-u-mt2">
                  <CommunicationHistoryDataTable MessageList={combinnedCommunicationData} MessageKeyList={historicalDataVariables} onSortData={onSortData} paginationReset={paginationReset} />
                </div>
              </div>}
              {selectedTabIndex === 1 && <div className='tab-container'>
                {isLoading && <div className='loader'><Spinner /></div>}
                {positionList && positionList.data && positionList.data.length > 0 &&
                  <>
                    <div className="sdds-row viewdata">
                      <div className='sdds-col-md-6'>
                        <span className="sdds-headline-06">{t("TS_core:DataLatencyByTimeline")}</span>
                        <InfoIconWithTooltip tooltipText={t("TS_core:DataLatencyByTimelineDetailInfo")} uniqueId='dataLatencyTimeInfoIcon' />
                        <InfoIconWithLabel labelText={t("TS_core:OffboardDelayNotCalculated")} />
                      </div>
                      <div className="sdds-col-md-6 right"><span ><Icons.InfoIcon />{t("TS_core:OnlyPositionMessagesAreUsedForTheChartAndMap")}</span></div>
                    </div>
                    <div className="sdds-u-mb2">
                      <TimeLineChart positionMessages={positionList.data} startTime={searchInput?.fromDate} endTime={searchInput?.toDate}></TimeLineChart>
                    </div>
                    <span className="sdds-headline-06">{t("TS_core:DataLatencyByLevel")}</span>
                    <InfoIconWithTooltip tooltipText={t("TS_core:DataLatencyByLevelDetailInfo")} uniqueId='dataLatencyInfoIcon' />
                    <div className="sdds-u-mb2">
                      <PieChart positionMessages={positionList.data}></PieChart>
                    </div>
                  </>
                }

                {positionList && positionList.data && positionList.data.length === 0 &&
                  <>
                    <NoDataFoundMessage />
                  </>
                }
              </div>}
              {selectedTabIndex === 2 && <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Assistance"]} >
                <>
                  <div className='tab-container map-container'>
                    {isLoading && <div className='loader'><Spinner /></div>}

                    {positionList && positionList.data && positionList.data.length > 0 &&
                      <>
                        <div className="sdds-row sdds-u-mb2 viewdata">
                          <div className="sdds-col-md-8 right"><span ><Icons.InfoIcon />{t("TS_core:OnlyPositionMessagesAreUsedForTheChartAndMap")}</span></div>
                        </div>
                        <CommunicationHistoryMap PositionList={positionList.data} />
                      </>
                    }
                    {positionList && positionList.data && positionList.data.length === 0 &&
                      <>
                        <NoDataFoundMessage />
                      </>
                    }
                  </div>
                </>
              </RBACWrapper>}
            </>
          </>
            :
            <>{isLoading && <div className='loader'><Spinner /></div>}</>}
        </div>

      </div>
    }
    <Toast toastQueue={toastQueue} />
  </>
};

export default CommunicationHistory;
