
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIconWithLabel from "../InfoIconWithLabel";
import { Spinner } from "@cd/sdds-common-components-react";

interface SCVehicleAlarmOverridePropTypes {
  externalEquipmentReference: string
  isAvailable: boolean
  csc5InitialValue?: number
  isOverrideApiLoading: boolean
  onAddCscCode5: (selectedValue: number) => void
  onDeleteCscCode5: () => void
}

const ServiceConfigurationVehicleAlarmOverride = ({ isAvailable, csc5InitialValue, onAddCscCode5, onDeleteCscCode5, isOverrideApiLoading }: SCVehicleAlarmOverridePropTypes) => {
  const { t } = useTranslation();
  const dropDownRef = useRef<any>(null);
  const [cscCode5Value, setCscCode5Value] = useState<number | undefined>();
  const [valueValidationMessage, setValueValidationMessage] = useState<string>();

  const onAddServiceClick = () => {
    if (!cscCode5Value) {
      setValueValidationMessage(t("Value is required"));
    } else {
      setValueValidationMessage('');
      onAddCscCode5(cscCode5Value)
    }

  }
  useEffect(() => {
    if (dropDownRef.current) {
      dropDownRef.current.addEventListener("selectOption", (evt: any) => {
        setCscCode5Value(evt.detail.value);
        if (valueValidationMessage) {
          setValueValidationMessage('');
        }
      });
    }
    setCscCode5Value(csc5InitialValue);
  }, [])

  useEffect(() => {
    if (csc5InitialValue === undefined) {
      dropDownRef.current?.resetOption();
      setCscCode5Value(undefined);
    } else {
      dropDownRef.current.selectedOption = csc5InitialValue;
      setCscCode5Value(csc5InitialValue);
    }
  }, [csc5InitialValue])
  return <>
    <div className="sdds-background-grey-50 sdds-row  sdds-u-pt2">
      <div className="sdds-col-md-12">
        <strong>{t("TS_core:Csc5SectionTitle")}</strong>
      </div>
    </div>
    <div className="sdds-background-grey-50 sdds-row sdds-u-pb2 ">
      <div className="sdds-col-md-12 sdds-u-mt2">
        <InfoIconWithLabel labelText={t("TS_core:VehicleAlarmOverideMsg")} />
      </div>
    </div>
    <div className="sdds-background-grey-50 sdds-row sdds-u-mb2">
      <div className="sdds-col-md-3 flex">
        <strong>{t("TS_core:Status")}: </strong>
        {isAvailable ? <span className="text-positive">{t("TS_core:Available")}</span> : <span className="sdds-text-red-400 sdds-u-ml1">&nbsp;{t("TS_core:Unavailable")}</span>}
      </div>
      <div className="sdds-col-md-3">
        <div className="flex">
          <strong>{t("TS_core:Value")}: </strong>
          <div className="value-dropdown">
            <sdds-dropdown ref={dropDownRef}
              placeholder={t("TS_core:ChooseAnOption")}
              selected-option={cscCode5Value}
              state={valueValidationMessage && "error"}
              helper={valueValidationMessage}>
              <sdds-dropdown-option value="0">0</sdds-dropdown-option>
              <sdds-dropdown-option value="1">1</sdds-dropdown-option>
            </sdds-dropdown></div>
        </div>
      </div>
      <div className="sdds-col-md-2 flex">
        {isOverrideApiLoading && <Spinner size="sm" />}
        {!isAvailable && !isOverrideApiLoading && <button type="button" className='sdds-btn sdds-btn-sm sdds-btn-primary sdds-btn-fullbleed' disabled={!cscCode5Value} onClick={onAddServiceClick}><span>{t("TS_core:AddService")}</span></button>}
        {isAvailable && <button type="button" className='sdds-btn sdds-btn-sm sdds-btn-secondary sdds-btn-fullbleed' disabled={cscCode5Value === csc5InitialValue} onClick={onAddServiceClick}><span>{t("TS_core:Save")}</span></button>}
      </div>
      <div className="sdds-col-md-2 flex">
        {isAvailable && <button className='sdds-btn sdds-btn-sm sdds-btn-danger sdds-btn-fullbleed  sdds-u-ml2' disabled={!isAvailable} onClick={onDeleteCscCode5}><span>{t("TS_core:DeleteService")}</span></button>}
      </div>
    </div>
  </>;
}

export default memo(ServiceConfigurationVehicleAlarmOverride);