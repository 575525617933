import React from "react";
import { useTranslation } from "react-i18next";
import HandleIcon from "../../assets/handle";
import ServiceActivationClientResultTable from "./ServiceActivationClientResultTable";
import { utcFormatFromString } from "../../../helpers/timeFormatter";
import { RBACWrapper } from "react-simple-rbac";
import { InfoIconWithTooltip } from "@cd/sdds-common-components-react/lib/esm/components/assets";

interface ServiceActivationStatusSectionPropTypes {
    Data: GroupedMachineryResult
    UniqueId: string
}

const ServiceActivationStatusSection = ({ Data, UniqueId }: ServiceActivationStatusSectionPropTypes) => {
    const { t } = useTranslation();
    return (
        <div className="section-box">
            <div className="status-section sdds-u-mt2">
                <div className="section-title">
                    <div className="section-reason">
                        <div className="sdds-headline-06">
                            <span>{t("TS_core:Reason")}</span>:
                            <span> {t("TS_core:" + Data?.reason).toUpperCase()}</span>
                        </div>
                    </div>
                    <div className="section-info">
                        {Data?.serviceActivationStatus &&
                            <div >
                                <span className="sdds-headline-06">{t("TS_core:Status")}: </span>
                                <span >{Data?.serviceActivationStatus}
                                </span>
                                <span id={`#csc-machinery-status-${UniqueId}`}>
                                    <InfoIconWithTooltip  tooltipText={`${t('TS_core:' + Data?.serviceActivationStatus)}`} uniqueId={`csc-machinery-status-${UniqueId}`} tooltipPlacement="bottom-end" />
                                </span>
                            </div>
                        }
                        {Data?.timeCreated &&
                            <div >
                                <span className="sdds-headline-06 tds-u-ml3">{t("TS_core:CreatedAt")}: </span>
                                <span>
                                    {Data?.timeCreated && utcFormatFromString(Data?.timeCreated)}{" "}
                                </span>
                            </div>
                        }

                    </div>
                </div>
            </div>
                <div id="root" className="sdds-row">
                {Data?.handle &&
                    <RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}>
                    <span className="handle-right">
                        <HandleIcon handleColor="black" />
                        <span className="sdds-headline-06">{t("TS_temp:Handle")}:</span> {Data?.handle}
                    </span>
                    </RBACWrapper>
                }
                <div className="latest-table">
                    <ServiceActivationClientResultTable clientResultList={Data?.clientResult} reason={Data.reason} />
                </div>
            </div>
        </div>
    )
}
export default ServiceActivationStatusSection;
