import React, { useState, useEffect } from 'react';
import { useIsAuthenticated, AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { InteractionType } from "@azure/msal-browser";
import './App.scss';
import { callCsttFacadeApis } from './adapters/UserAdapter';
import { loginRequest } from './authConfig';
import { Spinner, UserSettings, MOpinionFeedback } from '@cd/sdds-common-components-react';
import { useTranslation } from 'react-i18next';
import { RBACProvider, RBACWrapper } from 'react-simple-rbac';
import BatchSearch from './pages/BatchSearch';
import Communication from './pages/Communication';
import { UserContext } from './appContext';
import CommunicationHistory from './pages/Communication/CommunicationHistory';
import ServiceConfiguration from './pages/Communication/ServiceConfiguration';
import RTCLogUnitPage from './pages/Communication/RTCUnitLog';
import MPLog from './pages/Communication/MPLog';
import ConnectedServicesDeviation from './pages/ConnectedServicesDeviation';
import VehicleRtcHistory from './pages/VehicleRtcHistory';
import Index from './pages';

const App = () => {
  const isAuthenticated = useIsAuthenticated();
  const [loggedInProfileInfo, setLoggedInProfileInfo] = useState<ProfileInformationType>({
    "firstName": "",
    "lastName": "",
    "email": "",
    "role": "",
    "permissions": {
      "developer": false
    },
    "settings": {
      "csddashboard": "",
      "unit": "",
      "language": "en-GB"
    },
    "scaniaId": "",
    "impersonationAccess": false
  });

  const { i18n } = useTranslation();
  const [apiResponse, setApiResponse] = useState({});
  const [apiCalled, setApiCalled] = useState<string>('getProfileInfo');

  const [profileInfoLoader, setProfileInfoLoader] = useState<AsyncStateType>({ loading: true, error: false });

  useEffect(() => {
    if (isAuthenticated) {
      setApiCalled('getProfileInfo');
      callCsttFacadeApis(apiCalled, setApiResponse, setProfileInfoLoader, []);
    }
  // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    if(profileInfoLoader.error){
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.reload();
    }
  }, [profileInfoLoader]);

  useEffect(() => {
    setLoggedInProfileInfo(apiResponse as ProfileInformationType)
  }, [apiResponse]);

  useEffect(() => {
    i18n.changeLanguage(loggedInProfileInfo?.settings?.language || 'en-GB');
  }, [loggedInProfileInfo, i18n]);

  const onRetryLoginClick = () => {
    window.sessionStorage.clear()
    window.localStorage.clear();
    window.location.reload();
  }

  return (
    <div className="App">
      <sdds-theme global="true" />
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
      </MsalAuthenticationTemplate>
      <AuthenticatedTemplate>
        {profileInfoLoader.loading && (<Spinner size='lg' />)}
        {(!(profileInfoLoader.error || profileInfoLoader.loading)) && (
          <RBACProvider roles={[loggedInProfileInfo.role]} >
            <UserContext.Provider value={loggedInProfileInfo}>
              <Router >
                <Routes>
                  <Route path='/' element={<Index />} >
                    <Route path='connected-services-deviations/:idParam' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><ConnectedServicesDeviation loggedInProfileInfo={loggedInProfileInfo} /></RBACWrapper></>} />
                    <Route path='connected-services-deviations' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><ConnectedServicesDeviation loggedInProfileInfo={loggedInProfileInfo} /></RBACWrapper></>} />
                    <Route path='communication' element={<Communication loggedInProfileInfo={loggedInProfileInfo} />}/>
                    <Route path='equipment-batch-search' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><BatchSearch /></RBACWrapper></>} />
                    <Route path='vehicle-rtc-history' element={<VehicleRtcHistory />} />
                    <Route path='communication/:idParam' element={<Communication loggedInProfileInfo={loggedInProfileInfo} />} >
                      <Route path='communication-history' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic", "Assistance"]}><CommunicationHistory /></RBACWrapper></>} />
                      <Route path='service-configuration' element={<ServiceConfiguration />} />
                      <Route path='service-configuration/viewDetails' element={<ServiceConfiguration />} />
                      <Route path='rtc-log' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><RTCLogUnitPage /></RBACWrapper></>} />
                      <Route path='mp-communication-log' element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "FQ"]}><MPLog /></RBACWrapper></>} />
                    </Route> 
                  </Route>

                  <Route path="/user-settings" element={<><RBACWrapper oneOf requiredRoles={["Admin", "GTS", "LoTH", "FQ", "Basic","BasicLimited","Assistance"]}><UserSettings
                    userDetails={loggedInProfileInfo}
                    setLoggedInProfileInfo={setLoggedInProfileInfo}/>
                    </RBACWrapper></>} /> 
                </Routes>
              </Router>
            </UserContext.Provider>
          </RBACProvider>)}
        {profileInfoLoader.error && (<p>{'Profile Info Api : ' + profileInfoLoader.errorMessage} <button onClick={() => onRetryLoginClick()}>Retry Login</button></p>)}
      </AuthenticatedTemplate>
      {/* Mopinion form integration */}
      <MOpinionFeedback />
    </div>
  );
};

export default App;
