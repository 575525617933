
import { InfoIcon } from "@cd/sdds-common-components-react/lib/esm/components/assets";
import React from "react";
import { useTranslation } from "react-i18next";

const MachineryInfoIconSection = () => {
  const { t } = useTranslation();
  return (
    <div className="latest-label tds-u-mt2">
      <div className="tds-row tds-u-mt1">
        <span>
          <InfoIcon />
        </span>
        <span>{t("TS_core:HandleHelperText")}</span>
      </div>
      <div className="tds-row tds-u-mt1">
        <span>
          <InfoIcon />
        </span>
        <span>
          {t("TS_core:CSCCodeHelperText")}
        </span>
      </div>
    </div>)
}
export default MachineryInfoIconSection;
